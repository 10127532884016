
import React from "react";

function Icon() {
    return (
        <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.553711" width="70" height="70" rx="10" fill="#EBEFF7" />
            <path d="M41.2634 20.1147C41.2634 18.9258 40.3285 17.958 39.179 17.958L18.578 17.9583C17.4288 17.9583 16.4941 18.9258 16.4941 20.115V23.1772H41.2637L41.2634 20.1147ZM25.9679 21.7174C25.8362 21.8485 25.6466 21.9293 25.4496 21.9293C25.2599 21.9293 25.0698 21.8487 24.9384 21.7174C24.7996 21.5786 24.7194 21.3887 24.7194 21.1991C24.7194 21.0092 24.7996 20.8193 24.9384 20.6807C25.2085 20.4106 25.6977 20.4106 25.9682 20.6807C26.107 20.8195 26.1872 21.0092 26.1872 21.1991C26.1869 21.3887 26.1067 21.5789 25.9679 21.7174H25.9679ZM32.3042 21.929H27.9564C27.553 21.929 27.2262 21.6022 27.2262 21.1988C27.2262 20.7954 27.553 20.4685 27.9564 20.4685H32.3042C32.7076 20.4685 33.0344 20.7954 33.0344 21.1988C33.0344 21.6019 32.7076 21.929 32.3042 21.929Z" fill="url(#paint0_linear_32_351)" />
            <path d="M16.4941 50.9856C16.4941 52.1785 17.4291 53.1495 18.578 53.1495H39.1797C40.3291 53.1495 41.2641 52.1785 41.2641 50.9856V47.0663L16.4945 47.066L16.4941 50.9856ZM27.1805 48.4899H30.5772C30.9806 48.4899 31.3074 48.8167 31.3074 49.2201C31.3074 49.6235 30.9806 49.9503 30.5772 49.9503H27.1805C26.7771 49.9503 26.4502 49.6235 26.4502 49.2201C26.4502 48.8167 26.7773 48.4899 27.1805 48.4899Z" fill="url(#paint1_linear_32_351)" />
            <path d="M30.6855 31.5087H52.9999V33.0898H30.6855V31.5087Z" fill="url(#paint2_linear_32_351)" />
            <path d="M50.7365 27.4419H32.9493C31.7008 27.4419 30.6855 28.4568 30.6855 29.7056V30.0481H52.9999V29.7056C52.9999 28.4568 51.985 27.4419 50.7365 27.4419Z" fill="url(#paint3_linear_32_351)" />
            <path d="M30.6855 40.5339C30.6855 41.7824 31.7005 42.8048 32.9493 42.8048H50.7365C51.985 42.8048 52.9999 41.7824 52.9999 40.5339V34.5464L30.6855 34.5467V40.5339ZM48.5677 35.7807C49.8528 35.7807 50.9042 36.8321 50.9042 38.1172C50.9042 39.4022 49.8528 40.4465 48.5677 40.4465C47.2827 40.4465 46.2384 39.4025 46.2384 38.1172C46.2384 36.8321 47.2827 35.7807 48.5677 35.7807ZM45.4864 35.9266C45.041 36.5471 44.7782 37.2993 44.7782 38.1172C44.7782 38.9351 45.0412 39.6799 45.4793 40.3004C44.5812 39.9791 43.9313 39.1247 43.9313 38.1098C43.931 37.1023 44.5809 36.2478 45.4865 35.9266H45.4864ZM33.5187 36.4672H40.251C40.6599 36.4672 40.9812 36.7884 40.9812 37.1974C40.9812 37.5989 40.6599 37.9276 40.251 37.9276H33.5187C33.1097 37.9276 32.7884 37.5989 32.7884 37.1974C32.7884 36.7882 33.1097 36.4672 33.5187 36.4672ZM33.5187 38.9861H40.251C40.6599 38.9861 40.9812 39.3148 40.9812 39.7164C40.9812 40.1179 40.6599 40.4466 40.251 40.4466H33.5187C33.1097 40.4466 32.7884 40.1182 32.7884 39.7164C32.7884 39.3146 33.1097 38.9861 33.5187 38.9861Z" fill="url(#paint4_linear_32_351)" />
            <path d="M29.2255 40.5339V29.7052C29.2255 27.6521 30.8959 25.9811 32.9491 25.9811H41.2637V24.637H16.4941V45.6055H41.2637V44.2648H32.9491C30.8957 44.2651 29.2255 42.5912 29.2255 40.5339Z" fill="url(#paint5_linear_32_351)" />
            <defs>
                <linearGradient id="paint0_linear_32_351" x1="28.8789" y1="17.958" x2="28.8789" y2="23.1772" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint1_linear_32_351" x1="28.8791" y1="47.066" x2="28.8791" y2="53.1495" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint2_linear_32_351" x1="41.8427" y1="31.5087" x2="41.8427" y2="33.0898" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint3_linear_32_351" x1="41.8427" y1="27.4419" x2="41.8427" y2="30.0481" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint4_linear_32_351" x1="41.8427" y1="34.5464" x2="41.8427" y2="42.8048" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint5_linear_32_351" x1="28.8789" y1="24.637" x2="28.8789" y2="45.6055" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default Icon;
