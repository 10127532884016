import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import sing from "../../assets/images/singblack.png";
import db from "../../assets/images/db.png";
import pos from "../../assets/images/POS.png";
import { Product } from "./product";
import './style.css';

export const Products = () => {
    const products = [
      {
        title: "Manage Your Finances",
        subtitle: "Singularity",
        // img: template1,
        // description: 'Singularity is a neo-banking app that can be used by people and businesses alike to manage their identity, accounts, payments, bills, etc'
        description:
          "Singularity is a neo-banking app, that is tackling Financial Inclusion and Sustainable Developement with new and innovative solutions.",
      },
      {
        title: "Manage Your Payments",
        subtitle: "Singularity SPOS",
        // img: template2,
        description:
          "Our SPOS is a software point of sale payments system that allows merchants to accept different modes of payments including Card payments without the need for a hardware terminal",
      },
      {
        title: "Manage Your Business",
        subtitle: "Dabarhali",
        // img: template2,
        description:
          "Dabarhali is a new B2B Marketplace. As a business; you will be able to easily manage your purchasing needs as well as access to different financing options to help fulfill your order",
      },
    ];

    return (
        <React.Fragment>
            <div className='background-drop in-section'>
                <Container id="products" className="products-container in-section small-margin">
                    <div className='header limit'>
                        <div className='subtitle'>
                            Products
                        </div>
                        <div className='title dark'>
                            Coming Soon ...
                        </div>
                        <div className='description'>
                            {/* The fastest way to achieve inclusion is to create a full financial services eco */}
                        </div>
                    </div>
                    <Row style={{ marginTop: '2em' }}>
                        <Col style={{width : '100%'}} center xs={12} md={6}>
                            <Product
                                title={products[0].title}
                                subtitle={products[0].subtitle}
                                description={products[0].description}
                            />
                        </Col>

                    </Row>
                    {/* <Row>
                    
                        <Col xs={12} md={6}>
                            <div className={`product-content-container`}>
                                <Row className='product-content' style={{ alignItems: 'center', width: '100%' }}>
                                    <Col style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                        <a href='../singularity'>
                                            <img src={sing} alt='app' className='app-icons' title='Singularity' />
                                        </a>
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                        <a href='../dabarhali'>
                                            <img src={db} alt='app' className='app-icons' title='Dabarhali' />
                                        </a>
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                        <a href='../singularity%20spos'>
                                            <img src={pos} alt='app' className='app-icons' title='SPOS' />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </div>
        </React.Fragment>
    )
}
