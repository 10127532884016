import React, { useEffect } from 'react';
import './App.css';
import { LandingPage, SPOS, Singularity, Crypto, Dabarhali } from './pages';
import ReactGA from 'react-ga';
import { Routes, Route } from 'react-router-dom';
import { Header, Footer } from './components';
import { useLocation } from 'react-router-dom';

function App() {
  console.log('v1.2');
  useEffect(() => {
    ReactGA.initialize('G-MFW1J156TV');
    ReactGA.pageview(window.location.pathname);
  }, []);
  const location = useLocation();

  let isHomePage =
    location.pathname === '/' ||
    location.pathname === '/singularity%20crypto' ||
    location.pathname === '/singularity%20crypto/' ||
    location.pathname === '/singularity%20crypto/';

  return (
    <div
      className="App"
      style={{
        backgroundColor: isHomePage ? 'black' : '',
      }}
    >
      <span
        style={{
          backgroundColor: isHomePage ? 'black' : '',
        }}
      >
        <Header />
      </span>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dabarhali" element={<Dabarhali />} />
        <Route path="/singularity%20spos" element={<SPOS />} />
        <Route path="/singularity%20crypto" element={<Crypto />} />
        <Route path="/singularity" element={<Singularity />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
