import React, { useState } from "react";
import "./style.css";
import { Row, Col, Modal } from "react-bootstrap";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/604f6f20-0775-11ed-be50-e78da9ee852d";
// const FORM_ENDPOINT = "";

export const Contact = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };
    const handleClose = () => {
        setSubmitted(false)
    }


    return (
        <React.Fragment>
            <div id="contact" className="contact">
                <Row className="contact-section">

                    <Col className="header no-margin" md={4}>
                        <p className="title">Contact</p>
                        <div className="title"><div className="line-tick center" />
                            Us</div>
                    </Col>
                    <Col className='form-contact'>
                        <form
                            action={FORM_ENDPOINT}
                            onSubmit={handleSubmit}
                            target="_self"
                            method="POST"
                        >
                            <Row className="mobile">
                                <Col className="field-holder">
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        name="firstname"
                                        className="field"
                                        required
                                    />
                                </Col>
                                <Col className="field-holder">
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastname"
                                        className="field"
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mobile">
                                <Col className="field-holder">
                                    <input
                                        type="email"
                                        placeholder="E-mail"
                                        name="email"
                                        className="field"
                                        required
                                    />
                                </Col>
                                <Col className="field-holder">
                                    <input
                                        type="text"
                                        placeholder="Phone Number"
                                        name="number"
                                        className="field"
                                        aria-label="Num"
                                    />
                                </Col>
                            </Row>
                            <Row className="mobile">
                                <Col className="field-holder">
                                    <textarea
                                        placeholder="Message"
                                        name="message"
                                        className="field"
                                        required
                                    />
                                </Col>

                            </Row>
                            <Row className="mobile">
                                <Col>
                                    <button
                                        className="learn-more-button"
                                        type="submit"
                                    >
                                        Send
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Col>


                </Row>
            </div>
            <Modal show={submitted} onHide={handleClose} style={{ color: "#000000" }}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>Thanks! We'll be in touch</Modal.Body>
            </Modal>
        </React.Fragment >
    );
};
