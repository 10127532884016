import { Global } from '@emotion/react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { grey } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';


const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    // height: '50%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 134,
    height: 5,
    background: 'linear-gradient(93.74deg, #1C5BFF 0%, #14CAE3 103.16%)',
    borderRadius: 3,
    position: 'absolute',
    bottom: 8,
    left: 'calc(50% - 134px/2)',
    zIndex: 7
}));

function SwipeableEdgeDrawer({ list, open, toggleDrawer, drop, setDrop, locations, sublist }) {

    const handleClick = () => {
        setDrop(!drop);
    };
    const options = () => (
        <Box
            sx={{ width: 'auto', background: 'transparent', padding: '2rem' }}
            role="presentation"
        // onClick={toggleDrawer(false)}
        >
            <List>
                {list.map((text, i) => {
                    return (i !== 1 ? (<ListItem disablePadding key={i}>
                        <ListItemButton style={{ justifyContent: 'center' }} onClick={toggleDrawer(false)}>
                            <Link to={{ pathname: '/', hash: locations[i] }} replace style={{ textDecoration: 'none', color: 'white', fontFamily: 'Manrope', fontSize: 18 }}>
                                <ListItemText primary={text} />
                            </Link>
                        </ListItemButton>
                    </ListItem>) :
                        <div key={i} >
                            <ListItemButton onClick={handleClick} >
                                <ListItemText primary={text} />
                            </ListItemButton>
                            <Collapse in={drop} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding >
                                    {sublist.map((subtext, subi) => (
                                        <ListItemButton key={subi} onClick={toggleDrawer(false)} style={{ justifyContent: 'center' }}>
                                            <Link to={subtext.toLowerCase()} replace style={{ textDecoration: 'none', color: 'white', fontFamily: 'Manrope', fontSize: 18 }}>
                                                <ListItemText primary={subtext} />
                                            </Link>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </div>
                    )
                })}
            </List>
        </Box >
    );

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `25vh`,
                        overflow: 'visible',
                        background: 'linear-gradient(180deg, #01122F 0%, #2349A9 100%)',
                        borderRadius: '0px 0px 20px 20px'
                    },
                }}
            />
            <SwipeableDrawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                disableBackdropTransition
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        // top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        background: 'linear-gradient(180deg, #01122F 0%, #2349A9 100%)',
                        borderRadius: '0px 0px 20px 20px',
                        color: 'white'
                    }}
                >
                    {options()}
                    <Puller />
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}

export default SwipeableEdgeDrawer;
