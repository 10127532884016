import React from 'react';
import './style.css';
import { Row, Col } from "react-bootstrap";
import App from '../../assets/icons/app-store'
import Android from '../../assets/icons/android-store'
import Check from '../../assets/icons/checkbox'

export const TextCol = ({ Icon, title, text, subtitle = '', list = [], icons, check = [], theme = '', size = '', center = '', tck, button, align }) => {
    let whitepaper = text && text.indexOf('Whitepaper')
    return (
        <Row className={`product-text-col ${size} ${center}`}>
            {Icon && <div className={`product-icon ${center}`}>
                <Icon />
            </div>}
            {subtitle && <Row className={`subtitle ${theme} ${align}`}>
                {tck && <div className='line'></div>}
                {subtitle}
            </Row>}
            {title && <div className={`product-text-title ${theme}`}>{title}</div>}
            {whitepaper === -1 ?
                text &&
                <div className={`product-text-description ${theme}`}>
                    {text}
                </div> :
                text && <div className={`product-text-description ${theme}`}>
                    {text.slice(0, whitepaper)}
                    <span onClick={() => window.open('https://flare.xyz/whitepapers/', "_blank")
                    } style={{ cursor: 'pointer', color: '#4D7BF1' }}>Whitepaper</span>
                    {text.slice(whitepaper + 10, text.length)}
                </div>}
            {
                button &&
                <Row className='center light'>
                    <div className='sign-up-button visible blue'>
                        <p>
                            {button}
                        </p>
                    </div>
                </Row>
            }
            {
                list.length > 0 && <div className='product-list-container'>
                    {list.map((elem, i) => {
                        return (
                            <Col key={i} className='list-elem in-text'>
                                <Row className='list-row'>
                                    <Col className='number' xs={1}>
                                        {i + 1}
                                    </Col>
                                    <Col className='list-title'>
                                        {elem.title}
                                    </Col>
                                </Row>
                                <Row className='list-row'>
                                    <Col className='number invisible' xs={1}></Col>
                                    <Col className='list-text'>{elem.text}</Col>
                                </Row>
                            </Col>
                        )
                    })}
                </div>
            }
            {
                check.length > 0 && <Row className='product-list-container check'>
                    {check.map(elem => {
                        return (
                            <React.Fragment key={elem}>
                                <Col sm={1} xs={1}>
                                    <Check />
                                </Col>
                                <Col md={3} xs={5}>
                                    {elem}
                                </Col>
                            </React.Fragment>

                        )
                    })}
                </Row>
            }
            {
                icons && <Row className='store-holder sm'>
                    <Col className='icon-holder'>
                        <App />
                    </Col>
                    <Col className='icon-holder'>
                        <Android />
                    </Col>
                </Row>
            }
        </Row >
    )
}
