import React from "react";

function Icon() {
    return (
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="1.00024" width="27" height="27" rx="4.5" fill="#FFFFF" fillOpacity="0.1" stroke="url(#paint0_linear_32_271)" />
            <path d="M21 9.50024L11.375 18.5002L7 14.4093" stroke="#FFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_32_271" x1="0" y1="0.500244" x2="30.6429" y2="2.50569" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFFFF" />
                    <stop offset="1" stopColor="#14CAE3" />
                </linearGradient>
            </defs>
        </svg>


    );
}

export default Icon;
