import React, { useEffect } from 'react';
import './style.css';
import { ProductCard, SinglePhone, TextCol, Product } from '../../components';
import { useLocation } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import AppIm from '../../assets/images/sapp2.png';
import AppIm2 from '../../assets/images/sapp3.png';
import Transaction from '../../assets/images/transaction.png';
import Schedule from '../../assets/images/Schedule.png';
import AppIcon1 from '../../assets/icons/group-icon';
import AppIcon2 from '../../assets/icons/trade-icon';
import AppIcon3 from '../../assets/icons/money-icon';

export const Singularity = () => {
  const location = useLocation();

  useEffect(() => {
    const background = document.getElementById('background');
    background.style.backgroundColor = 'white';
  }, [location]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="super-container landing">
      <div className="in-section no-margin fit">
        <ProductCard
          title="Manage your finances"
          text="Singularity can be used to easily manage your day-to-day spending, savings and payments of all kinds; whether it’s for you or your business"
          description="Singularity is a Neo-Bank"
          subtitle="Singularity a neo-banking app"
          img={"sapp"}
        ></ProductCard>
      </div>

      <Row className="in-section neg mobile-rev small fit">
        <Col>
          <SinglePhone img={AppIm} pos="left" />
        </Col>
        <Col className="center-self">
          <TextCol
            Icon={AppIcon1}
            title="Stay on top of “Your” spending"
            text="Singularity allows you to easily track and access your transactional history for each account you hold with Us"
          />
        </Col>
      </Row>
      <Row className="in-section neg rev-neg">
        <Col className="center-self">
          <TextCol
            Icon={AppIcon2}
            title="Setup different accounts with ease"
            text="Singularity Users will be able to hold multiple accounts with us, similar to your Bank Customer Number"
            list={[
              {
                title: "Singularity Customer",
                text: "With your Singularity Client ID, Users will be able to open; multiple personal and business accounts",
              },
              {
                title: "Supplementary Account",
                text: "For the Parents, we have the supplementray account feature. Similar to it’s bank counterpart, our supplementary account allows Parents full access and control over these seperate yet connect accounts",
              },
            ]}
          />
        </Col>
        <Col>
          <SinglePhone img={AppIm2} pos="right" />
        </Col>
      </Row>
      <Row className="in-section dark">
        <Col className="hide-mobile">
          <Image src={Transaction} alt="singularity" />
        </Col>
        <Col className="center-self no-margin">
          <TextCol
            Icon={AppIcon3}
            title="Send money with ease"
            text={`With our Network feature, you can easily setup your favorites and create groups.\n
                        This allows users the flexibility to manage their transfers for a seamless experience`}
            // button='Download Now'
            center=""
          />
        </Col>
      </Row>
      <Row className="in-section smaller">
        <Col>
          <Product
            title="Schedule your Payments"
            description={`With our Calendar; you can easily view and setup your payments.\n
                    This way, You never lose track of your responsibilities.\n
                    All of this with an easy to use UI/UX`}
            gray="gray"
            img={Schedule}
            slanted="slanted"
            single={"single"}
            size="big"
          />
        </Col>
      </Row>
      <Row className="in-section small special">
        <div className="big-title">
          <h1>Coming Soon ...</h1>
        </div>
        <Row className="mobile-col">
          <Col className="big-title-col" md={4}>
            UN Sustainability Development Goals
          </Col>
          <Col md={4}>
            <Product
              title="No Poverty"
              description={`“End poverty in all its forms everywhere” \n
                            To be achieved through Financial Empowerment and Enlightenment`}
              readmore={"https://sdgs.un.org/goals/goal1"}
              size="big"
              orientation={"down"}
            />
          </Col>
          <Col md={4}>
            <Product
              title="Decent work and economic growth"
              description="“Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all”"
              readmore={"https://sdgs.un.org/goals/goal8"}
              size="big"
              orientation={"down"}
            />
          </Col>
          {/* <Col md={4}>
            <Product
              title="Gender Equality"
              description={`“Achieve gender equality and empower all women and girls” \n
                            Empowering female entrepreneurs is essential for economic growth`}
              readmore={"https://sdgs.un.org/goals/goal5"}
              size="big"
              orientation={"down"}
            />
          </Col> */}
        </Row>
      </Row>
    </div>
  );
};
