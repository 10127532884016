import React, { useEffect } from 'react';
import './style.css';
import Ellipse from '../../assets/icons/ellipse';
import {
  Architecture,
  Finance,
  Products,
  Team,
  Contact,
} from '../../components';
import { useLocation } from 'react-router-dom';

export const LandingPage = () => {
  const location = useLocation();
  const { pathname, hash, key } = location;
  useEffect(() => {
    const background = document.getElementById('background');
    background.style.backgroundColor = '#000000';
    return () => {
      background.style.backgroundColor = 'white';
    };
  }, []);
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'end',
        });
      }
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div
      className="super-container landing white"
      style={{
        backgroundColor: 'black',
      }}
    >
      <Ellipse deg="39.01deg" top="5vh" right="-5vh" background={'#fffff'} />
      <Finance />

      <Architecture />
      <Products />
      <Team />
      <Contact />
    </div>
  );
};
