import React, { useEffect } from 'react';
import BL from '../../assets/images/botleft.svg';
import BR from '../../assets/images/botright.svg';
import Coins from '../../assets/images/CoinsC.png';
import Singularity from '../../assets/images/Singularity3d.png';
import TL from '../../assets/images/topleft.svg';
import TR from '../../assets/images/topright.svg';
import './style.css';
// import Next from '../../assets/icons/next';
// import Bulb from "../../assets/icons/bulb";
import Cog from '../../assets/icons/cog';
import Coins2 from '../../assets/images/3Coins.png';
import FLR from '../../assets/images/FLR.png';
import FTSO from '../../assets/images/ftso.png';
import HG from '../../assets/images/hourglass.png';
// import Down from "../../assets/icons/down";
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { TextCol } from '../../components';
// import { Link } from "react-router-dom";
import NextBlue from '../../assets/icons/next-blue';

export const Crypto = () => {
  const location = useLocation();
  useEffect(() => {
    const background = document.getElementById('background');
    background.style.backgroundColor = 'white';
  }, [location]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="super-container special">
      <div className="in-section black">
        <Col className="order">
          <Row className="coins">
            <img src={TL} alt="dabarhali" className="t l" />
            <img src={Singularity} alt="singularity" className="sm-img" />
            <img src={TR} alt="dabarhali" className="t r" />
          </Row>
          <Row className="header group">
            <TextCol
              subtitle={'Manage your Crypto & HODL'}
              title="Singularity Crypto"
              text="Singularity Crypto is a Non-custodial Digital Assets Wallet.
                        Manage your Crypto, NFTs and digital assets with ease"
              theme="dark2"
              size="smaller"
            />
            {/* <div className="learn-more-button button-text margin">Get Started<Next /></div> */}
          </Row>
          <Row className="coins">
            <img src={BL} alt="dabarhali" className="b l" />
            <img
              src={Singularity}
              alt="singularity"
              className="sm-img invisible"
            />
            <img src={BR} alt="dabarhali" className="b r" />
          </Row>
        </Col>
      </div>
      <Col className="in-section center">
        <Row className="header">
          <TextCol
            title="Non-Custodial Wallets / Different Currencies"
            subtitle="World of cryptocurrencies"
            text={`Hold your Digital Assets and NFTs safely. \n Our wallet supports multiple currencies and we will be updating the list regularly. We will be support different NFT Marketplaces as well as DeFi projects. \n Singularity is going to be fully compatible with different chains starting with Flare and all the functionalities associated with the ecosystem`}
            size="small"
            theme="blue"
            tck={true}
          />
        </Row>
        <Row className="img-holder">
          <img src={Coins} alt="coins" className="move-up" />
        </Row>
      </Col>
      <Col className="in-section margin fit-smaller">
        <Row className="header no-margin">
          <TextCol
            title={'Flare: a breakdown'}
            subtitle="FLARE’s Ecosystem Support"
            text="The Flare Network Whitepaper introduced the Flare token (FLR) and the Flare Time Series Oracle (FTSO) as the decentralised on-chain mechanism providing time series estimates to the network.
                            With Singularity FTSO, Users will be able to Delegate their Votes and Earn their FTSO rewards with ease all through their Singularity account"
            theme="blue"
            tck={true}
          />
        </Row>
        <Row>
          <Col className="grey-card-2">
            <img src={FLR} alt="FLR" />
            <Row className="small-title">The Flare Network and FLR token</Row>
            <Row className="product-text-description">
              Flare is the 1st Turing-complete FBA Network in the world
            </Row>
            <Row
              className={`view-more f-bold read-more`}
              onClick={() => window.open('https://flare.xyz/', '_blank')}
            >
              <div>
                Read More
                <NextBlue />
              </div>
            </Row>
          </Col>
          <Col className="grey-card-2">
            <img src={FTSO} alt="FLR" />
            <Row className="small-title">
              The Flare Network and What is the FTSO?
            </Row>
            <Row className="product-text-description">
              The FTSO provides externally sourced data estimates to Flare
            </Row>
            <Row
              className={`view-more f-bold read-more`}
              onClick={() =>
                window.open('https://flare.xyz/ftso-a-breakdown/', '_blank')
              }
            >
              <div>
                Read More
                <NextBlue />
              </div>
            </Row>
          </Col>
          <Col className="grey-card-2">
            <img src={HG} alt="FLR" />
            <Row className="small-title">
              The State-Connector and Attestation Providers
            </Row>
            <Row className="product-text-description">
              The State Connector allows anyone to query information from
              outside Flare
            </Row>
            <Row
              className={`view-more f-bold read-more`}
              onClick={() =>
                window.open(
                  'https://docs.flare.network/tech/state-connector/',
                  '_blank'
                )
              }
            >
              <div>
                Read More
                <NextBlue />
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
      <div
        className="in-section margin fit-smaller"
        style={{ margin: 'auto 0' }}
      >
        <Row>
          <Col>
            <TextCol
              title={'Singularity’s Partnership Strategy'}
              subtitle="the blockchain ecosystem"
              text="Develop new partnerships with other Ecosystems; that have the same focus and ethos regarding Africa and Real-World adoption of Blockchain Technology"
              center="center"
              theme="blue"
              tck={true}
              align="align"
            />
            <Row className="noshow">
              <img src={Coins2} alt="coins" />
            </Row>
            <Row className="grey-card space">
              {/* <Col className="icn-title" md={10} xs={10}> */}
              <Col className="icn" md={2} xs={2}>
                <img src={FTSO} alt="FLR" width="40" height="40" />
              </Col>
              <Col className="small-title">Singularity FTSO</Col>
              {/* </Col> */}
              {/* <Col className="icn" xs={2}>
                                <Down />
                            </Col> */}
            </Row>
            <Row className="grey-card space" xs={1}>
              {/* <Col className="icn-title" md={10} xs={10}> */}
              <Col className="icn" md={1} xs={1}>
                <Cog />
              </Col>
              <Col className="small-title" xs={10}>
                Singularity Attestation Data Provider
              </Col>
              {/* </Col> */}
              {/* <Col className="icn" xs={2}>
                                <Down />
                            </Col> */}
              {/* <div className="description">
                                Singularity will be a part of Flare Network’s next phase, by working on always adding new use cases to the Kind of Data attested by DApps on the Network’s State Connector
                            </div> */}
            </Row>
            {/* <Row style={{ alignItems: "center" }}>
                            <Col xs={6}>
                                <div className="learn-more-button button-text margin">Get Started<Next /></div>
                            </Col>
                            <Col className={`view-more f-bold read-more`} xs={6}>
                                <Link to={`/`}>
                                    Read More
                                    <NextBlue />
                                </Link>
                            </Col>
                        </Row> */}
          </Col>
          <Col className="finance-artice-section-image-container delete">
            <img src={Coins2} alt="coins" />
          </Col>
        </Row>
      </div>
      <div className="in-section margin remove-padding">
        <div className="dark-blue-card">
          <TextCol
            subtitle={'Join  community'}
            title="Join our Community"
            text="Ask questions, engage with our community, learn about singularity and become familiar with our partner ecosystems"
            theme="dark2"
            size="small"
            tck={true}
          />
          <div className="dark">
            <div
              className="sign-up-button visible"
              onClick={() => {
                window.open('https://twitter.com/singularityfinc?s=11');
              }}
            >
              <p>Get Started</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
