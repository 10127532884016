import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LinkedIn from '../../assets/icons/linkedin';
import Twitter from '../../assets/icons/twitter';
import './style.css';
import { useLocation } from 'react-router-dom';
import SvgLogo from '../header/logo';
export const Footer = () => {
  let theme = 'dark';
  const location = useLocation();
  if (location.pathname !== '/') theme = 'light';
  return (
    <div className={theme}>
      <Row className="contact-section-detail-container">
        {/* <Col className="contact-info-container" md={4}> */}
        <SvgLogo theme={theme} />
        <p className="contact-detail">
          Dubai International Financial Centre, DIFC, Level 14,The Gate PO, Box
          74777, Dubai, UAE
        </p>
        {/* </Col> */}
        {/* <Col md={6}>
                    <Row className="contact-option-container">
                         <Col className="contact-option-row">
                            <h2>Pages</h2>
                            <p>FAQs</p>
                            <p>Features</p>
                            <p>Transfer Pricing</p>
                        </Col>
                        <Col className="contact-option-row">
                            <h2>Company</h2>
                            <p>About Us</p>
                            <p>Careers</p>
                            <p>Privacy</p>
                        </Col> 
                        <Col className="contact-option-row">
                            <h2>Social</h2>
                            <p onClick={() => {
                                window.open('https://www.linkedin.com/company/singularity-finance-egypt')
                            }}>LinkedIn</p>
                            <p onClick={() => {
                                window.open('https://twitter.com/singularityfinc?s=11')
                            }}>Twitter</p>
                        </Col>
                    </Row>
                </Col> */}
      </Row>
      <Row id="footer" className="footer-container">
        <Col style={{ flexGrow: 2 }}>
          <p>
            Copyright @ 2024 Singularity Innovations Limited. All rights
            reserved
          </p>
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <Row className="footer-icon-container">
            {/* <Col> */}
            <Twitter
              onClick={() => {
                window.open('https://twitter.com/singularityfinc?s=11');
              }}
            />
            {/* </Col>
                    <Col> */}
            <LinkedIn
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/company/singularity-finance-egypt'
                );
              }}
            />
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
