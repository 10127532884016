import React from 'react';
import './style.css';
import { Col, Row } from "react-bootstrap";


export const MiddlePhone = ({ img, list }) => {
    const l1 = list.slice(0, list.length / 2)
    const l2 = list.slice(list.length / 2, list.length)
    return (
        <React.Fragment>
            <Row className={`mid-phone-holder`}>
                <Col style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>{
                        l1.map(
                            (elem, i) => <Col key={i} className='list-elem left'>
                                <Row className='number grey' md={2}>
                                    {i + 1}
                                </Row>

                                <Row className='list-title'>
                                    {elem.title}
                                </Row>
                                <Row className='list-text'>{elem.text}</Row>
                            </Col>
                        )}
                </Col>
                <Col>
                    <div className='spos-photo'></div>
                </Col>
                <Col style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>{
                        l2.map(
                            (elem, i) => <Col key={i} className='list-elem'>
                                <Row className='number grey' md={2}>
                                    {i + list.length / 2 + 1}
                                </Row>
                                <Row className='list-title'>
                                    {elem.title}
                                </Row>
                                <Row className='list-text'>{elem.text}</Row>
                            </Col>
                        )}
                </Col>
            </Row >
            <Row className={`mobile-mid-phone-holder`}>
                <Row>
                    <div className='spos-photo'></div>
                </Row>
                <Row className='center-row'>{
                    list.map(
                        (elem, i) => <Col key={i} className='list-elem mid' xs={6} >
                            <Row className='number grey' >
                                {i + 1}
                            </Row>
                            <Row className='list-title'>
                                {elem.title}
                            </Row>
                            <Row className='list-text'>{elem.text}</Row>
                        </Col>
                    )}
                </Row>
            </Row >
        </React.Fragment>

    )
}
