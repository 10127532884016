import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Next from '../../assets/icons/next';
import Singularity from "../../assets/images/Singularity3d.png";
import { PartnerBar } from "../../components";
import './style.css';

export const Finance = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };
    const handleClose = () => {
        setSubmitted(false)
    }
    return (
        <div className='in-section no-margin adjust-bottom'>
            <Row className="row-container-image mobile-rev">
                <Col className="finance-article-section">
                    <p className="finance-article-section-subtitle subtitle">Finance at Your Finger Tips</p>
                    <h1 className="finance-article-section-title title f-bold white">Realise your potential by embracing Financial Enablement</h1>
                    <p className="finance-article-section-description description f-regular">We all have personal expenses, bills & payments of different kinds; whether it’s to manage our household or our businesses.
                        Check out our broad range of products designed to help manage all your finances with the tip of your finger
                    </p>
                    <div className="learn-more-button button-text" onClick={handleSubmit}>Subscribe<Next /></div>
                </Col>
                <div className="finance-artice-section-image-container">
                    <img src={Singularity} alt='singularity' />
                </div>
            </Row>
            {/* <PartnerBar /> */}
            <Modal show={submitted} onHide={handleClose} style={{ color:"#000000" }}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{ height: '80vh' }}>
                    <iframe title='signup' src="https://docs.google.com/forms/d/e/1FAIpQLSdbc42lFsl3nEzzaM-D22CY3dYyY5I0cGXxatjcpgm0cjdKeA/viewform?embedded=true" width={'100%'} height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </Modal.Body>
            </Modal>
        </div>
    )
}
