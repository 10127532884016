import React from "react";
import "./style.css";
import AWS from "../../assets/images/aws.png";
import Azure from "../../assets/images/azure.png";
import Flare from "../../assets/images/flare.png";
import MasterCard from "../../assets/images/mastercard.png";
import Ovamba from "../../assets/images/ovamba.png";
import PCIDSS from "../../assets/images/pcidss.png";
// import Visa from "../../assets/images/visa.png";
import VisaWhite from "../../assets/images/VisaWhite.png";
import { Row, Col } from "react-bootstrap";

export const PartnerBar = ({ blue }) => {
    const partners = [Flare, Azure, AWS, PCIDSS, Ovamba, MasterCard, VisaWhite]
    return (
        <Row className={`partner-container ${blue}`}>
            {partners.map(
                (partner, i) =>
                    <Col key={i} className='partner-col'>
                        <img src={partner} alt='partner' className="small-img" />
                    </Col>)
            }
        </Row>
    )
}