import React, { useEffect } from "react"
import "./style.css"
import {
  ProductCard,
  SinglePhone,
  TextCol,
  Product,
  MiddlePhone,
} from "../../components"
import { useLocation } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import AppIm from "../../assets/images/spos2.png"
import AppIm2 from "../../assets/images/Mobile Screen.png"
import AppIm3 from "../../assets/images/spos4.png"
import SPOSIcon from "../../assets/icons/spos-icon"

export const SPOS = () => {
  const location = useLocation()
  useEffect(() => {
    const background = document.getElementById("background")
    background.style.backgroundColor = "#FFFFF"
  }, [location])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className='super-container'>
      <ProductCard
        title='Singularity S-POS'
        text='Singularity has partnered wit MyPinPad the leading Software POS provider in the World; to usher in a new era of payments acceptance in Egypt, Middle East & Africa'
        subtitle='Get rid of the bulky hardware'
        img={"spos"}
        blue='blue'
      ></ProductCard>
      <Row className='in-section big'>
        <div className='big-title'>Accept payments in seconds</div>
        <div className='big-subtitle subtitle'>
          SPOS is a software point of sale payments system that allows merchants
          to accept different modes of payments including Card payments; without
          the need for a hardware terminal
        </div>
        <Col>
          <MiddlePhone
            img={AppIm}
            list={[
              {
                title: "Easy Setup",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
              {
                title: "Price Convenience",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
              {
                title: "No Hardware",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
              {
                title: "Accept Payments Anywhere",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
              {
                title: "Issue Invoices with Ease",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
              {
                title: "Full Transactional History",
                // text: 'SPOS is a software point of sale payments system that allows merchants to accept'
              },
            ]}
          />
        </Col>
      </Row>
      <Row className='in-section gradient mobile-rev'>
        <Col>
          <SinglePhone img={AppIm2} pos='mid' simple='simple' />
        </Col>
        <Col>
          <TextCol
            Icon={SPOSIcon}
            title='Accept Cash'
            text='Our SPOS has a “Pay by Cash” feature, this is to allow our merchants to issue invoices on their cash transactions. 
                        This way, merchants keep a full record of their transactional history'
            check={["PCI DSS", "PCI SPoC"]}
            // icons={true}
          />
        </Col>
      </Row>
      <Row className='in-section big fit-smaller'>
        <Product
          subtitle='Payments System Software'
          title='Track your transactions'
          description='Our SPOS allows merchants to easily access their transactional histroy with an easy-to-use UI'
          color='green'
          img={AppIm3}
          slanted='slanted'
          check={["PCI DSS", "PCI SPoC"]}
        />
      </Row>
    </div>
  )
}
