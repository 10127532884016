import React from "react";

function Icon() {
    return (

        <svg viewBox="0 0 189 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M182.276 56H6.6206C2.9708 56 0 53.0208 0 49.3738V6.6164C0 2.968 2.9708 0 6.6206 0H182.276C185.924 0 189 2.968 189 6.6164V49.3738C189 53.0208 185.924 56 182.276 56Z" fill="#A6A6A6" />
            <path d="M187.645 49.3753C187.645 52.3377 185.245 54.7373 182.277 54.7373H6.62069C3.65409 54.7373 1.24609 52.3377 1.24609 49.3753V6.61509C1.24609 3.65409 3.65409 1.24609 6.62069 1.24609H182.276C185.245 1.24609 187.644 3.65409 187.644 6.61509L187.645 49.3753Z" fill="#000000" />
            <path d="M42.1787 27.6977C42.1381 23.1855 45.8733 20.9903 46.0441 20.8881C43.9287 17.8039 40.6499 17.3825 39.4977 17.3489C36.7439 17.0591 34.0727 18.9967 32.6699 18.9967C31.2391 18.9967 29.0789 17.3769 26.7507 17.4245C23.7547 17.4707 20.9519 19.2053 19.4147 21.8989C16.2423 27.3911 18.6083 35.4621 21.6477 39.9015C23.1681 42.0757 24.9447 44.5033 27.2701 44.4179C29.5451 44.3241 30.3949 42.9675 33.1403 42.9675C35.8605 42.9675 36.6585 44.4179 39.0301 44.3633C41.4717 44.3241 43.0089 42.1793 44.4761 39.9855C46.2331 37.4935 46.9387 35.0393 46.9667 34.9133C46.9093 34.8937 42.2249 33.1059 42.1787 27.6977Z" fill="#FFFFF" />
            <path d="M37.6979 14.4285C38.9215 12.8983 39.7587 10.8165 39.5263 8.70386C37.7553 8.78226 35.5405 9.92886 34.2651 11.4255C33.1367 12.7443 32.1287 14.9059 32.3891 16.9387C34.3785 17.0871 36.4211 15.9349 37.6979 14.4285Z" fill="#FFFFF" />
            <path d="M75.102 44.1056H71.9226L70.181 38.633H64.1274L62.4684 44.1056H59.373L65.3706 25.4744H69.075L75.102 44.1056ZM69.656 36.337L68.081 31.472C67.9144 30.975 67.6022 29.8046 67.1416 27.9622H67.0856C66.9022 28.7546 66.6068 29.925 66.2008 31.472L64.6538 36.337H69.656Z" fill="#FFFFF" />
            <path d="M90.5272 37.2233C90.5272 39.5081 89.9098 41.3141 88.675 42.6399C87.569 43.8201 86.1956 44.4095 84.5562 44.4095C82.7866 44.4095 81.5154 43.7739 80.7412 42.5027H80.6852V49.5797H77.7004V35.0939C77.7004 33.6575 77.6626 32.1833 77.5898 30.6713H80.2148L80.3814 32.8007H80.4374C81.4328 31.1963 82.9434 30.3955 84.9706 30.3955C86.5554 30.3955 87.8784 31.0213 88.9368 32.2743C89.998 33.5287 90.5272 35.1779 90.5272 37.2233ZM87.4864 37.3325C87.4864 36.0249 87.1924 34.9469 86.6016 34.0985C85.9562 33.2137 85.0896 32.7713 84.0032 32.7713C83.2668 32.7713 82.5976 33.0177 81.9998 33.5035C81.4006 33.9935 81.0086 34.6333 80.8252 35.4257C80.7328 35.7953 80.6866 36.0977 80.6866 36.3357V38.5757C80.6866 39.5529 80.9862 40.3775 81.5854 41.0509C82.1846 41.7243 82.963 42.0603 83.9206 42.0603C85.0448 42.0603 85.9198 41.6263 86.5456 40.7611C87.1728 39.8945 87.4864 38.7521 87.4864 37.3325Z" fill="#FFFFF" />
            <path d="M105.979 37.2233C105.979 39.5081 105.362 41.3141 104.125 42.6399C103.021 43.8201 101.647 44.4095 100.008 44.4095C98.2384 44.4095 96.9672 43.7739 96.1944 42.5027H96.1384V49.5797H93.1536V35.0939C93.1536 33.6575 93.1158 32.1833 93.043 30.6713H95.668L95.8346 32.8007H95.8906C96.8846 31.1963 98.3952 30.3955 100.424 30.3955C102.007 30.3955 103.33 31.0213 104.391 32.2743C105.448 33.5287 105.979 35.1779 105.979 37.2233ZM102.938 37.3325C102.938 36.0249 102.643 34.9469 102.052 34.0985C101.407 33.2137 100.543 32.7713 99.455 32.7713C98.7172 32.7713 98.0494 33.0177 97.4502 33.5035C96.851 33.9935 96.4604 34.6333 96.277 35.4257C96.186 35.7953 96.1384 36.0977 96.1384 36.3357V38.5757C96.1384 39.5529 96.438 40.3775 97.0344 41.0509C97.6336 41.7229 98.412 42.0603 99.3724 42.0603C100.497 42.0603 101.372 41.6263 101.997 40.7611C102.625 39.8945 102.938 38.7521 102.938 37.3325Z" fill="#FFFFF" />
            <path d="M123.254 38.8808C123.254 40.4656 122.704 41.755 121.599 42.7504C120.385 43.8382 118.695 44.3814 116.524 44.3814C114.519 44.3814 112.912 43.995 111.695 43.2208L112.387 40.733C113.697 41.5254 115.135 41.923 116.702 41.923C117.826 41.923 118.701 41.6682 119.33 41.1614C119.955 40.6546 120.268 39.9742 120.268 39.1258C120.268 38.3698 120.01 37.7328 119.493 37.2162C118.98 36.6996 118.121 36.2194 116.923 35.7756C113.661 34.559 112.031 32.7768 112.031 30.4332C112.031 28.9016 112.603 27.6458 113.746 26.6686C114.886 25.69 116.406 25.2014 118.308 25.2014C120.003 25.2014 121.411 25.4968 122.536 26.0862L121.789 28.5194C120.739 27.9482 119.552 27.6626 118.224 27.6626C117.174 27.6626 116.353 27.9216 115.765 28.4368C115.268 28.8974 115.019 29.4588 115.019 30.1238C115.019 30.8602 115.303 31.4692 115.874 31.948C116.371 32.3904 117.274 32.8692 118.585 33.3858C120.188 34.0312 121.365 34.7858 122.123 35.651C122.877 36.5134 123.254 37.5928 123.254 38.8808Z" fill="#FFFFF" />
            <path d="M133.123 32.9111H129.833V39.4337C129.833 41.0927 130.413 41.9215 131.575 41.9215C132.108 41.9215 132.55 41.8753 132.9 41.7829L132.983 44.0495C132.395 44.2693 131.621 44.3799 130.662 44.3799C129.483 44.3799 128.562 44.0201 127.897 43.3019C127.235 42.5823 126.901 41.3755 126.901 39.6801V32.9083H124.941V30.6683H126.901V28.2085L129.833 27.3237V30.6683H133.123V32.9111Z" fill="#FFFFF" />
            <path d="M147.967 37.2777C147.967 39.3427 147.376 41.0381 146.198 42.3639C144.961 43.7289 143.321 44.4093 141.275 44.4093C139.304 44.4093 137.735 43.7555 136.564 42.4479C135.394 41.1403 134.809 39.4897 134.809 37.5003C134.809 35.4185 135.411 33.7133 136.619 32.3875C137.824 31.0603 139.451 30.3967 141.496 30.3967C143.468 30.3967 145.054 31.0505 146.251 32.3595C147.396 33.6293 147.967 35.2687 147.967 37.2777ZM144.87 37.3743C144.87 36.1353 144.606 35.0727 144.07 34.1865C143.444 33.1141 142.549 32.5793 141.39 32.5793C140.19 32.5793 139.279 33.1155 138.653 34.1865C138.117 35.0741 137.852 36.1535 137.852 37.4303C137.852 38.6693 138.117 39.7319 138.653 40.6167C139.298 41.6891 140.2 42.2239 141.363 42.2239C142.503 42.2239 143.398 41.6779 144.043 40.5887C144.593 39.6857 144.87 38.6119 144.87 37.3743Z" fill="#FFFFF" />
            <path d="M157.668 33.2963C157.373 33.2417 157.058 33.2137 156.727 33.2137C155.677 33.2137 154.865 33.6099 154.294 34.4037C153.797 35.1037 153.548 35.9885 153.548 37.0567V44.1057H150.564L150.592 34.9021C150.592 33.3537 150.555 31.9439 150.48 30.6727H153.08L153.189 33.2431H153.272C153.587 32.3597 154.084 31.6485 154.764 31.1151C155.429 30.6349 156.148 30.3955 156.922 30.3955C157.198 30.3955 157.447 30.4151 157.668 30.4501V33.2963Z" fill="#FFFFF" />
            <path d="M171.017 36.753C171.017 37.2878 170.982 37.7386 170.908 38.1068H161.954C161.989 39.434 162.421 40.449 163.253 41.149C164.008 41.7748 164.983 42.0884 166.182 42.0884C167.508 42.0884 168.717 41.877 169.805 41.4528L170.273 43.5248C169.001 44.0792 167.501 44.355 165.769 44.355C163.686 44.355 162.05 43.7418 160.86 42.5168C159.673 41.2918 159.078 39.6468 159.078 37.5832C159.078 35.5574 159.631 33.8704 160.739 32.525C161.898 31.0886 163.464 30.3704 165.436 30.3704C167.372 30.3704 168.838 31.0886 169.833 32.525C170.621 33.666 171.017 35.0772 171.017 36.753ZM168.171 35.9788C168.191 35.094 167.996 34.3296 167.592 33.6842C167.075 32.854 166.281 32.4396 165.213 32.4396C164.237 32.4396 163.443 32.8442 162.837 33.6562C162.34 34.3016 162.045 35.0758 161.954 35.9774H168.171V35.9788Z" fill="#FFFFF" />
            <path d="M68.6701 14.0127C68.6701 15.6605 68.1759 16.9009 67.1889 17.7339C66.2747 18.5025 64.9755 18.8875 63.2927 18.8875C62.4583 18.8875 61.7443 18.8511 61.1465 18.7783V9.77487C61.9263 9.64887 62.7663 9.58447 63.6735 9.58447C65.2765 9.58447 66.4847 9.93307 67.2995 10.6303C68.2123 11.4185 68.6701 12.5455 68.6701 14.0127ZM67.1231 14.0533C67.1231 12.9851 66.8403 12.1661 66.2747 11.5949C65.7091 11.0251 64.8831 10.7395 63.7953 10.7395C63.3333 10.7395 62.9399 10.7703 62.6137 10.8347V17.6793C62.7943 17.7073 63.1247 17.7199 63.6049 17.7199C64.7277 17.7199 65.5943 17.4077 66.2047 16.7833C66.8151 16.1589 67.1231 15.2489 67.1231 14.0533Z" fill="#FFFFF" />
            <path d="M76.874 15.4519C76.874 16.4669 76.5842 17.2985 76.0046 17.9509C75.397 18.6215 74.592 18.9561 73.5868 18.9561C72.618 18.9561 71.8466 18.6355 71.2712 17.9915C70.6972 17.3489 70.4102 16.5383 70.4102 15.5611C70.4102 14.5391 70.7056 13.7005 71.2992 13.0495C71.8928 12.3985 72.6908 12.0723 73.696 12.0723C74.6648 12.0723 75.4432 12.3929 76.0326 13.0355C76.5926 13.6599 76.874 14.4663 76.874 15.4519ZM75.3522 15.4995C75.3522 14.8905 75.2206 14.3683 74.9588 13.9329C74.6508 13.4065 74.2126 13.1433 73.6428 13.1433C73.0534 13.1433 72.6054 13.4065 72.2974 13.9329C72.0342 14.3683 71.904 14.8989 71.904 15.5261C71.904 16.1351 72.0356 16.6573 72.2974 17.0927C72.6152 17.6191 73.0576 17.8823 73.6288 17.8823C74.1888 17.8823 74.6284 17.6149 74.9448 17.0787C75.2164 16.6349 75.3522 16.1085 75.3522 15.4995Z" fill="#FFFFF" />
            <path d="M87.8706 12.2068L85.8056 18.8064H84.4616L83.6062 15.9406C83.3892 15.2252 83.2128 14.514 83.0756 13.8084H83.049C82.9216 14.5336 82.7452 15.2434 82.5184 15.9406L81.6098 18.8064H80.2504L78.3086 12.2068H79.8164L80.5626 15.3442C80.7432 16.0862 80.8916 16.7932 81.0106 17.4624H81.0372C81.1464 16.9108 81.327 16.208 81.5818 15.3582L82.5184 12.2082H83.714L84.6114 15.291C84.8284 16.0428 85.0048 16.7666 85.1406 17.4638H85.1812C85.2806 16.7848 85.4304 16.061 85.6292 15.291L86.43 12.2082H87.8706V12.2068Z" fill="#FFFFF" />
            <path d="M95.4782 18.8063H94.011V15.0263C94.011 13.8615 93.5686 13.2791 92.681 13.2791C92.2456 13.2791 91.8942 13.4387 91.6212 13.7593C91.351 14.0799 91.2138 14.4579 91.2138 14.8905V18.8049H89.7466V14.0925C89.7466 13.5129 89.7284 12.8843 89.6934 12.2039H90.9828L91.0514 13.2357H91.092C91.2628 12.9151 91.5176 12.6505 91.8522 12.4391C92.2498 12.1927 92.695 12.0681 93.1822 12.0681C93.7982 12.0681 94.3106 12.2669 94.718 12.6659C95.2248 13.1545 95.4782 13.8839 95.4782 14.8527V18.8063Z" fill="#FFFFF" />
            <path d="M99.5224 18.8063H98.0566V9.17847H99.5224V18.8063Z" fill="#FFFFF" />
            <path d="M108.161 15.4519C108.161 16.4669 107.871 17.2985 107.292 17.9509C106.684 18.6215 105.878 18.9561 104.874 18.9561C103.904 18.9561 103.132 18.6355 102.558 17.9915C101.984 17.3489 101.697 16.5383 101.697 15.5611C101.697 14.5391 101.993 13.7005 102.586 13.0495C103.18 12.3985 103.978 12.0723 104.982 12.0723C105.952 12.0723 106.729 12.3929 107.32 13.0355C107.88 13.6599 108.161 14.4663 108.161 15.4519ZM106.638 15.4995C106.638 14.8905 106.506 14.3683 106.244 13.9329C105.938 13.4065 105.498 13.1433 104.93 13.1433C104.339 13.1433 103.891 13.4065 103.584 13.9329C103.321 14.3683 103.191 14.8989 103.191 15.5261C103.191 16.1351 103.323 16.6573 103.584 17.0927C103.902 17.6191 104.345 17.8823 104.916 17.8823C105.476 17.8823 105.914 17.6149 106.23 17.0787C106.503 16.6349 106.638 16.1085 106.638 15.4995Z" fill="#FFFFF" />
            <path d="M115.262 18.8063H113.944L113.835 18.0461H113.795C113.344 18.6523 112.701 18.9561 111.867 18.9561C111.244 18.9561 110.74 18.7559 110.36 18.3583C110.016 17.9971 109.844 17.5477 109.844 17.0143C109.844 16.2079 110.18 15.5933 110.856 15.1677C111.531 14.7421 112.48 14.5335 113.702 14.5433V14.4201C113.702 13.5507 113.246 13.1167 112.332 13.1167C111.681 13.1167 111.107 13.2805 110.611 13.6053L110.313 12.6421C110.926 12.2627 111.683 12.0723 112.577 12.0723C114.301 12.0723 115.167 12.9823 115.167 14.8023V17.2327C115.167 17.8921 115.199 18.4171 115.262 18.8063ZM113.739 16.5383V15.5205C112.12 15.4925 111.311 15.9363 111.311 16.8505C111.311 17.1949 111.403 17.4525 111.592 17.6247C111.781 17.7969 112.022 17.8823 112.309 17.8823C112.631 17.8823 112.932 17.7801 113.207 17.5771C113.482 17.3727 113.652 17.1137 113.715 16.7959C113.73 16.7245 113.739 16.6377 113.739 16.5383Z" fill="#FFFFF" />
            <path d="M123.599 18.8063H122.297L122.228 17.7465H122.188C121.772 18.5529 121.063 18.9561 120.068 18.9561C119.273 18.9561 118.611 18.6439 118.086 18.0195C117.561 17.3951 117.299 16.5845 117.299 15.5891C117.299 14.5209 117.583 13.6557 118.154 12.9949C118.707 12.3789 119.385 12.0709 120.191 12.0709C121.077 12.0709 121.698 12.3691 122.05 12.9669H122.078V9.17847H123.547V17.0283C123.547 17.6709 123.564 18.2631 123.599 18.8063ZM122.078 16.0231V14.9227C122.078 14.7323 122.064 14.5783 122.038 14.4607C121.955 14.1079 121.777 13.8111 121.507 13.5717C121.234 13.3323 120.905 13.2119 120.526 13.2119C119.978 13.2119 119.55 13.4289 119.235 13.8643C118.923 14.2997 118.765 14.8555 118.765 15.5345C118.765 16.1869 118.914 16.7161 119.215 17.1235C119.533 17.5575 119.962 17.7745 120.498 17.7745C120.979 17.7745 121.364 17.5939 121.657 17.2313C121.94 16.8967 122.078 16.4935 122.078 16.0231Z" fill="#FFFFF" />
            <path d="M136.147 15.4519C136.147 16.4669 135.858 17.2985 135.278 17.9509C134.67 18.6215 133.867 18.9561 132.86 18.9561C131.893 18.9561 131.121 18.6355 130.545 17.9915C129.971 17.3489 129.684 16.5383 129.684 15.5611C129.684 14.5391 129.979 13.7005 130.573 13.0495C131.166 12.3985 131.964 12.0723 132.971 12.0723C133.938 12.0723 134.718 12.3929 135.306 13.0355C135.866 13.6599 136.147 14.4663 136.147 15.4519ZM134.627 15.4995C134.627 14.8905 134.495 14.3683 134.234 13.9329C133.924 13.4065 133.487 13.1433 132.916 13.1433C132.328 13.1433 131.88 13.4065 131.571 13.9329C131.308 14.3683 131.177 14.8989 131.177 15.5261C131.177 16.1351 131.309 16.6573 131.571 17.0927C131.889 17.6191 132.331 17.8823 132.902 17.8823C133.462 17.8823 133.903 17.6149 134.22 17.0787C134.49 16.6349 134.627 16.1085 134.627 15.4995Z" fill="#FFFFF" />
            <path d="M144.035 18.8063H142.57V15.0263C142.57 13.8615 142.127 13.2791 141.238 13.2791C140.803 13.2791 140.451 13.4387 140.18 13.7593C139.908 14.0799 139.772 14.4579 139.772 14.8905V18.8049H138.304V14.0925C138.304 13.5129 138.287 12.8843 138.252 12.2039H139.54L139.609 13.2357H139.649C139.821 12.9151 140.076 12.6505 140.409 12.4391C140.808 12.1927 141.252 12.0681 141.741 12.0681C142.355 12.0681 142.868 12.2669 143.275 12.6659C143.783 13.1545 144.035 13.8839 144.035 14.8527V18.8063Z" fill="#FFFFF" />
            <path d="M153.91 13.3056H152.294V16.5116C152.294 17.3264 152.581 17.7338 153.15 17.7338C153.413 17.7338 153.631 17.7114 153.804 17.6652L153.841 18.7782C153.552 18.8874 153.171 18.942 152.702 18.942C152.122 18.942 151.671 18.7656 151.345 18.4128C151.018 18.06 150.855 17.4664 150.855 16.6334V13.3056H149.891V12.2066H150.855V10.997L152.293 10.563V12.2052H153.909V13.3056H153.91Z" fill="#FFFFF" />
            <path d="M161.677 18.8063H160.208V15.0543C160.208 13.8713 159.766 13.2791 158.88 13.2791C158.199 13.2791 157.735 13.6221 157.48 14.3081C157.436 14.4523 157.411 14.6287 157.411 14.8359V18.8049H155.945V9.17847H157.411V13.1559H157.439C157.901 12.4321 158.563 12.0709 159.422 12.0709C160.029 12.0709 160.532 12.2697 160.931 12.6687C161.428 13.1657 161.677 13.9049 161.677 14.8821V18.8063Z" fill="#FFFFF" />
            <path d="M169.69 15.1942C169.69 15.4574 169.67 15.6786 169.635 15.8592H165.235C165.254 16.5116 165.464 17.0086 165.872 17.353C166.244 17.661 166.724 17.815 167.312 17.815C167.963 17.815 168.557 17.7114 169.092 17.5028L169.321 18.522C168.696 18.7936 167.959 18.9294 167.107 18.9294C166.085 18.9294 165.28 18.6284 164.697 18.0264C164.112 17.4244 163.822 16.6166 163.822 15.603C163.822 14.6076 164.092 13.7788 164.637 13.118C165.205 12.4124 165.974 12.0596 166.944 12.0596C167.893 12.0596 168.614 12.4124 169.102 13.118C169.495 13.678 169.69 14.371 169.69 15.1942ZM168.29 14.8148C168.301 14.3794 168.204 14.0042 168.005 13.6878C167.751 13.2804 167.363 13.076 166.838 13.076C166.359 13.076 165.968 13.2748 165.67 13.6738C165.427 13.9916 165.282 14.371 165.235 14.8148H168.29Z" fill="#FFFFF" />
        </svg>

    );
}

export default Icon;
