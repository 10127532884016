import React from 'react';
import './style.css';
import { Row, Col } from "react-bootstrap";
import NextDark from '../../assets/icons/next-dark'
import NextBlue from '../../assets/icons/next-blue'
import { Link } from "react-router-dom";
import App from '../../assets/icons/app-store'
import Android from '../../assets/icons/android-store'
import Check from '../../assets/icons/white-checkbox'

export const Product = ({ title, subtitle, description, img, readmore, orientation, color, slanted, check = [], single, size }) => {
    return (
        <div className={`product-content-container ${single} ${color}`}>
            <Row className={`product-content ${orientation} ${single} ${color} ${slanted}`}>
                <Col className={`text-content ${orientation}`}>
                    <Row className='subtitle'>{subtitle}</Row>
                    <Row className={`small-title ${size}`}>{title}</Row>
                    <Row className='description'>{description}</Row>
                    <Row className='product-list-container check'>
                        {check.map(elem => {
                            return (
                                <React.Fragment key={elem}>
                                    <Col sm={1} xs={1}>
                                        <Check />
                                    </Col>
                                    <Col md={3} xs={5}>
                                        {elem}
                                    </Col>
                                </React.Fragment>

                            )
                        })}
                    </Row>
                    <Row>
                        {!readmore ?
                            subtitle && color !== 'green' &&
                            < Col md={4} className={`view-more f-bold`}>
                                <Link to={`/${subtitle.toLowerCase()}`}>
                                    View More
                                    <NextDark />
                                </Link>
                            </Col>
                            :
                            readmore &&
                            <Col md={4} className={`view-more f-bold read-more`}>
                                <div onClick={() => window.open(readmore, "_blank")}>
                                    Read More
                                    <NextBlue />
                                </div>
                            </Col>
                        }
                    </Row>
                    {
                        color === 'green' && <Row className='store-holder sm invisible'>
                            <Col className='icon-holder'>
                                <App />
                            </Col>
                            <Col className='icon-holder'>
                                <Android />
                            </Col>
                        </Row>
                    }
                </Col >
                {img && <Col className={`photos ${img ? '' : 'invisible'} ${slanted}`}>
                    <img src={img} height={color !== 'green' ? '70%' : '80%'} alt={title}></img>
                </Col>}
            </Row >
        </div>

    )
}
