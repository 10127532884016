import React from 'react';
import './style.css';
import { Row, Col } from "react-bootstrap";
// import App from '../../assets/icons/app-store'
// import Android from '../../assets/icons/android-store'

export const ProductCard = ({ title, subtitle, description, img, text, blue }) => {
    return (
        <Row className={`product-card-content ${blue}`}>
            <Col className='text-content'>
                <Row className='subtitle'>{subtitle}</Row>
                <div>
                    <Row className='title'>{title}</Row>
                    <Row className='description'>{description}</Row>
                </div>

                <Row className='text'>{text}</Row>
                {/* <Row className='store-holder'>
                    <Col className='icon-holder'>
                        <App />
                    </Col>
                    <Col className='icon-holder'>
                        <Android />
                    </Col>
                </Row> */}
            </Col>
            <Col className='illustration'>
                <div className={img}></div>
            </Col>
        </Row>
    )
}
