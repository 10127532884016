import React from "react";

function Icon() {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.553711" width="25" height="25" rx="5" fill="url(#paint0_linear_32_367)" />
            <path d="M19 8.55371L10.75 16.5537L7 12.9173" fill="url(#paint1_linear_32_367)" />
            <path d="M19 8.55371L10.75 16.5537L7 12.9173" stroke="#FFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_32_367" x1="12.5" y1="0.553711" x2="12.5" y2="25.5537" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
                <linearGradient id="paint1_linear_32_367" x1="13" y1="8.55371" x2="13" y2="16.5537" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1D3BB2" />
                    <stop offset="1" stopColor="#173095" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default Icon;
