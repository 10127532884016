import React from 'react';

export default ({ deg, top, right, left, marginTop, background = '' }) => {
  return (
    <div
      className="ellipse"
      style={{
        right,
        top,
        left,
        transform: `rotate(${deg})`,
        marginTop,
        backgroundColor: background,
      }}
    ></div>
  );
};
