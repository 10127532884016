import React from "react";

function Icon() {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9L9 1" stroke="url(#paint0_linear_211_520)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1H9V9" stroke="url(#paint1_linear_211_520)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_211_520" x1="1" y1="1" x2="9.75512" y2="1.57298" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1C5BFF" />
                    <stop offset="1" stopColor="#14CAE3" />
                </linearGradient>
                <linearGradient id="paint1_linear_211_520" x1="1" y1="1" x2="9.75512" y2="1.57298" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1C5BFF" />
                    <stop offset="1" stopColor="#14CAE3" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default Icon;
