import React from "react";
import Factory from "../images/factory.svg";
import Deal from "../images/deal.svg";
import Connect from "../images/connect.svg";
import Bill from "../images/bill.svg";

function Icon({ type }) {
    let PickedIcon
    switch (type) {
        case 0:
            PickedIcon = Factory
            break;
        case 1:
            PickedIcon = Deal
            break;
        case 2:
            PickedIcon = Bill
            break;
        case 3:
            PickedIcon = Connect
            break;
        default:
            break;
    }
    return (
        <div className="blue-holder">
            <img src={PickedIcon} alt={type} />
        </div>

    );
}

export default Icon;
