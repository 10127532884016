import React from "react";

function Icon() {
    return (
        <svg width="30" height="30" viewBox="0 0 37 37" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.5" cy="18.5" r="18.5" fill="#0077b5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8617 13.1725C14.8617 14.1057 14.1052 14.8622 13.1721 14.8622C12.2389 14.8622 11.4824 14.1057 11.4824 13.1725C11.4824 12.2394 12.2389 11.4829 13.1721 11.4829C14.1052 11.4829 14.8617 12.2394 14.8617 13.1725ZM16.4571 25.5166V16.1426H19.251V17.4245H19.2904C19.6788 16.6885 20.6286 15.9109 22.0457 15.9109C24.9971 15.9109 25.5408 17.8515 25.5408 20.3767V25.5174H22.6287V20.9582C22.6287 19.8716 22.6102 18.4731 21.1144 18.4731C19.5977 18.4731 19.3676 19.6577 19.3676 20.8809V25.5166H16.4571ZM14.6292 16.1426H11.7141V25.5166H14.6292V16.1426Z" fill="#FFFFF" />
        </svg>

    );
}

export default Icon;
