import React from 'react';
import './style.css';
import { Row } from "react-bootstrap";


export const SinglePhone = ({ img, pos, simple }) => {
    return (
        <Row className={`phone-holder ${pos} ${simple}`}>
            <div className='phone-background'></div>
            <img src={img} alt='phone'></img>
        </Row>
    )
}
