import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import Drawer from "./drawer";
import SvgLogo from "./logo";
import "./style.css";
import { Link } from "react-router-dom";
const locations = ["", "products", "team", "contact"];
const menuLists = ["Home", "Products", "Team", "Contact Us"];
const products = ["Singularity"];

export const Header = () => {
  let theme = "dark";
  const location = useLocation();

  let isHomePage = location.pathname === "/";

  if (
    location.pathname !== "/" &&
    location.pathname !== "/dabarhali/" &&
    location.pathname !== "/dabarhali" &&
    location.pathname !== "/singularity%20crypto" &&
    location.pathname !== "/singularity%20crypto/"
  )
    theme = "light";

  const [open, setOpen] = React.useState(false);
  const [drop, setDrop] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    setDrop(false);
    setOpen(open);
  };

  return (
    <span
      style={{
        backgroundColor: isHomePage ? "black" : "transparent",
        paddingTop: "0px",
        marginTop: "0px",
      }}
    >
      <React.Fragment>
        <Drawer
          list={menuLists}
          locations={locations}
          open={open}
          toggleDrawer={toggleDrawer}
          drop={drop}
          setDrop={setDrop}
          sublist={products}
        />
        <div
          className={`header-container ${theme}`}
          style={{
            backgroundColor: isHomePage ? "black" : "transparent",
          }}
        >
          <div className="header-subcontainer">
            <a href={locations[0]}>
              <SvgLogo theme={theme} />
            </a>
            <ul className="list-container">
              {menuLists.map((item, i) =>
                i === 1 ? (
                  <DropdownButton
                    variant={theme === "dark" ? "dark" : "info"}
                    id="dropdown-basic-button"
                    title={item}
                    style={{
                      backgroundColor: "transparent",
                      border: "transparent",
                    }}
                    key={i}
                  >
                    {products.map((prod) => (
                      <Dropdown.Item
                        key={prod}
                        href={"../" + prod.toLowerCase()}
                        style={{ textDecoration: "none", fontWeight: "500" }}
                      >
                        {/* <Link to={prod.toLowerCase()} replace style={{ textDecoration: 'none', color: theme === 'dark' ? 'white' : 'black', fontWeight: '500' }}> */}
                        {prod}
                        {/* </Link> */}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ) : (
                  <li key={i} className="tab-menu-lists">
                    <Link to={{ pathname: "/", hash: locations[i] }} replace>
                      {item}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="burger-menu" onClick={toggleDrawer(true)}>
            <GiHamburgerMenu
              color={theme === "dark" ? "white" : "black"}
            ></GiHamburgerMenu>
          </div>
          {/* <div className="sign-up-button"><p>Get Started</p></div> */}
        </div>
      </React.Fragment>
    </span>
  );
};
