import React from "react";

function Icon() {
    return (
        <svg width="30" height="30" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.5" cy="18.5" r="18.5" fill="#405067" />
            <path d="M28.0696 13.1477C27.4284 13.429 26.7395 13.6191 26.0154 13.705C26.7626 13.2623 27.3216 12.5656 27.5881 11.7449C26.8861 12.1577 26.1178 12.4483 25.3165 12.604C24.7777 12.0344 24.0641 11.6569 23.2864 11.53C22.5086 11.4032 21.7104 11.5341 21.0155 11.9024C20.3206 12.2708 19.768 12.856 19.4435 13.5671C19.1189 14.2783 19.0406 15.0757 19.2207 15.8354C17.7982 15.7647 16.4066 15.3986 15.1363 14.761C13.866 14.1234 12.7453 13.2284 11.8469 12.1341C11.5397 12.6588 11.3631 13.267 11.3631 13.9148C11.3628 14.498 11.5078 15.0722 11.7854 15.5866C12.063 16.1009 12.4645 16.5395 12.9543 16.8634C12.3862 16.8455 11.8307 16.6935 11.3339 16.4201V16.4657C11.3339 17.2836 11.6196 18.0764 12.1427 18.7094C12.6658 19.3425 13.394 19.7769 14.2037 19.9389C13.6767 20.0801 13.1243 20.1009 12.588 19.9997C12.8164 20.7035 13.2615 21.3189 13.8607 21.7598C14.46 22.2007 15.1835 22.445 15.93 22.4586C14.6628 23.4435 13.0978 23.9778 11.4868 23.9754C11.2014 23.9755 10.9162 23.959 10.6328 23.926C12.2681 24.967 14.1717 25.5195 16.1159 25.5174C22.6971 25.5174 26.2949 20.1206 26.2949 15.44C26.2949 15.288 26.2911 15.1344 26.2841 14.9823C26.984 14.4812 27.588 13.8608 28.0681 13.1499L28.0696 13.1477Z" fill="#FFFFF" />
        </svg>

    );
}

export default Icon;
