import React from "react";
import Ahmed from "../../assets/images/Ahmed.jpeg";
// import Eid from "../../assets/images/eid.png";
import Hossam from "../../assets/images/hossam.png";
import Hugo from "../../assets/images/hugo.png";
// import Lina from "../../assets/images/lina.jpg";
import Hassan from "../../assets/images/Hassan.jpeg";
import Mehdi from "../../assets/images/mehdi.png";
import AhmedSalah from "../../assets/images/salah.png";
import Sara from "../../assets/images/Sara.jpeg";
import Simon from "../../assets/images/simon.png";
import MoTaysir from "../../assets/images/taysir.jpg";
import Ibra from "../../assets/images/AhmedTaysir.png";
import Toby from "../../assets/images/thompson.png";
import sokk from "../../assets/images/Aly.jpeg";
import Taha from "../../assets/images/Taha.JPG";
// import Tim from "../../assets/images/Tim.JPG";
import Engy from "../../assets/images/Engy.jpg";

import "./style.css";

import { Col, Row } from "react-bootstrap";
import Ellipse from "../../assets/icons/ellipse";
import LinkedIn from "../../assets/icons/linkedin";
const Hazem =
  "https://tstoragee.vercel.app/GetFile/BQACAgQAAxkDAAIJ2GQcLei_KP9VNJCnUJ0h4tQ-QcTuAAKjDwACNA3hUG1nu08MLFxaLwQ";
const team = [
  {
    name: "Mohamed Taysir",
    role: "Co-Founder / CEO",
    img: MoTaysir,
    url: "https://www.linkedin.com/in/mohamed-taysir-4b898149",
  },
  {
    name: "Ahmed Taysir",
    role: "Co-Founder / CFO",
    img: Ibra,
    url: "https://www.linkedin.com/in/ahmed-taysir-7b161322a/",
  },

  {
    name: "Ahmed Salah",
    role: "Co-Founder / COO",
    img: AhmedSalah,
    url: "https://www.linkedin.com/in/ahmed-salah-099b4658",
  },
  {
    name: "Aly Hassan Elsokkary",
    role: "Full Stack Developer",
    img: sokk,
    url: "https://www.linkedin.com/in/ali-hassan-el-sokkary-035867169/",
  },
  {
    name: "Taha Shalaby",
    role: "Software Lead",
    img: Taha,
    url: "https://www.linkedin.com/in/tahashalaby/",
  },
  {
    name: "Engy Kamel",
    role: "Operations Specialist",
    img: Engy,
    url: "https://www.linkedin.com/in/engy-ahmed-9b72711b6/",
  },
];
const advisors = [
  {
    name: "Sara Tabana",
    role: "Head of MENA Digital Partnerships, Fintech & Ventures @ Visa",
    img: Sara,
    url: "https://www.linkedin.com/in/saratabana/",
  },
  {
    name: "Hassan El Sokkary",
    role: "Digital Business Consultant",
    img: Hassan,
    url: "https://www.linkedin.com/in/hassan-elsokkary-bb526333/",
  },
  {
    name: "Ahmed Atef",
    role: "Head of Cards Portfolio EG Bank",
    img: Ahmed,
    url: "https://www.linkedin.com/in/ahmed-atef-276a7454",
  },
  {
    name: "Hossam El Sokkary",
    role: "Head of Distribution & Logistics",
    img: Hossam,
  },
  {
    name: "Toby Norfolk-Thompson",
    role: "Chief Commercial Officer, Trident Digital",
    img: Toby,
    url: "https://www.linkedin.com/in/toby-norfolk-thompson-78687b58/",
  },
  {
    name: "Hugo Phillion",
    role: "CEO / Co-founder, Flare",
    img: Hugo,
    url: "https://www.linkedin.com/in/hugo-philion-7562843a",
  },
  {
    name: "Simon Burrows",
    role: "Associate Partner Strategy & Transactions Ernest Young",
    img: Simon,
    url: "https://www.linkedin.com/in/sburrows",
  },
];

export const Team = () => {
  return (
    <React.Fragment>
      <div id="team" className="team">
        <div className="in-section" style={{ height: "auto" }}>
          <div className="team-backdrop">TEAM</div>
          <div className="header limit">
            <Row className="subtitle">Team Members</Row>
            <Row className="title">Our Team Members</Row>
            {/* <Row className="description">
              The fastest way to achieve inclusion is to create a full financial services eco
            </Row> */}
          </div>
          <Row className="content">
            {team.map((member, index) => {
              return (
                <Col className="member-img-container" key={index} md={3}>
                  {member.url && (
                    <div
                      className="icon"
                      onClick={() => {
                        window.open(member.url);
                      }}
                    >
                      <LinkedIn />
                    </div>
                  )}
                  <img src={member.img} alt="member" />
                  <p className="member-name">{member.name}</p>
                  <div className="member-role">{member.role}</div>
                </Col>
              );
            })}
          </Row>
        </div>

        <Ellipse left={"-7em"} />
        <div className="header limit">
          <Row className="title">Advisors</Row>
        </div>
        <Row className="content">
          {advisors.map((member, index) => {
            return (
              <Col className="member-img-container" key={index} md={3}>
                {member.url && (
                  <div
                    className="icon"
                    onClick={() => {
                      window.open(member.url);
                    }}
                  >
                    <LinkedIn />
                  </div>
                )}
                <img className="member-img" src={member.img} alt="member" />
                <p className="member-name">{member.name}</p>
                <div className="member-role">{member.role}</div>
              </Col>
            );
          })}
        </Row>
      </div>
    </React.Fragment>
  );
};
