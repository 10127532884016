import React from "react";

function Icon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="6" fill="#EBF2FE" />
            <path d="M26.7829 16.4033L28.5895 14.2681L25.7328 11.4114L23.598 13.2182C23.2629 13.0463 22.9149 12.9011 22.5572 12.7836L22.3252 10H18.2853L18.0534 12.7836C17.6956 12.9011 17.3477 13.0463 17.0127 13.2182L14.8775 11.4116L12.021 14.2683L13.8276 16.4033C13.6557 16.7384 13.5105 17.0865 13.3929 17.4441L10.6094 17.6759V21.7161L13.3929 21.9479C13.5105 22.3055 13.6557 22.6536 13.8276 22.9886L12.021 25.1236L14.8777 27.9803L17.0129 26.1737H17.0127C17.3478 26.3456 17.6956 26.4909 18.0534 26.6084L18.2853 29.3919H22.3252L22.557 26.6084H22.5573C22.9149 26.4909 23.263 26.3456 23.598 26.1737L25.7332 27.9803L28.5899 25.1236L26.7829 22.9886C26.9548 22.6536 27.1002 22.3055 27.2177 21.9479L30.0013 21.7161V17.6759L27.2177 17.4441C27.1002 17.0864 26.9548 16.7383 26.7829 16.4033H26.7829ZM20.3056 25.3518C18.8054 25.3518 17.3667 24.7559 16.3061 23.6953C15.2453 22.6345 14.6494 21.196 14.6494 19.6959C14.6494 18.1957 15.2453 16.7572 16.3061 15.6964C17.3667 14.6358 18.8054 14.0397 20.3056 14.0397C21.8055 14.0397 23.2442 14.6358 24.305 15.6964C25.3656 16.7572 25.9615 18.1957 25.9615 19.6959C25.9615 21.196 25.3656 22.6345 24.305 23.6953C23.2442 24.7559 21.8055 25.3518 20.3056 25.3518Z" fill="#061C62" />
            <path d="M20.3056 15.6559C19.2341 15.6559 18.2064 16.0816 17.4488 16.8391C16.6911 17.5969 16.2656 18.6244 16.2656 19.6958C16.2656 20.7673 16.6911 21.7948 17.4488 22.5526C18.2064 23.3101 19.2341 23.7358 20.3056 23.7358C21.377 23.7358 22.4046 23.3101 23.1623 22.5526C23.9198 21.7948 24.3455 20.7673 24.3455 19.6958C24.3455 18.6244 23.9198 17.5969 23.1623 16.8391C22.4046 16.0816 21.377 15.6559 20.3056 15.6559ZM20.3056 21.7161C19.7698 21.7161 19.2561 21.5033 18.8771 21.1243C18.4983 20.7455 18.2855 20.2318 18.2855 19.6961C18.2855 19.1603 18.4983 18.6465 18.8771 18.2678C19.2561 17.8888 19.7699 17.676 20.3056 17.676C20.8413 17.676 21.3551 17.8888 21.7338 18.2678C22.1126 18.6466 22.3254 19.1603 22.3254 19.6961C22.3254 20.2318 22.1126 20.7456 21.7338 21.1243C21.3551 21.5033 20.8413 21.7161 20.3056 21.7161Z" fill="#061C62" />
        </svg>
    );
}

export default Icon;
