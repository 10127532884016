
import React from "react";

function Icon() {
    const fillcolor = "#FFFFFF"
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" width="70" height="70" rx="10" fill="#F4F7FD" />
            <path d="M30.1366 24.4183C29.1779 23.6602 28.2929 22.6174 28.0286 21.2189C27.9741 20.9308 27.9613 20.6698 27.942 20.4046C27.4312 20.6103 26.8256 20.9534 26.6917 21.4216C26.5393 21.951 27.0464 22.5963 27.4985 23.0436C28.058 23.5983 29.0634 24.0959 30.1366 24.4183H30.1366Z" fill={fillcolor} />
            <path d="M33.1782 24.9097C33.6505 24.8371 34.138 24.7871 34.6523 24.7859C34.7684 22.197 33.7949 21.4425 33.7844 21.4345C33.5773 21.2887 33.5202 21.0003 33.6612 20.7899C33.8032 20.5794 34.0826 20.5164 34.2954 20.6538C34.4514 20.753 35.726 21.7056 35.5811 24.8392C35.9826 24.8758 36.3686 24.9342 36.7374 25.016C38.0421 23.4595 41.4296 19.3379 41.2117 16.9179C41.168 16.436 40.9787 16.0622 40.6323 15.7744C37.771 13.401 33.8547 17.2369 33.8151 17.2768C33.6901 17.4012 33.5083 17.4545 33.3371 17.4226C33.3109 17.4173 30.8021 16.9551 29.6116 18.1455C29.0105 18.7452 28.831 19.713 29.0783 21.0211C29.5226 23.3716 32.2765 24.5751 33.1782 24.91L33.1782 24.9097Z" fill={fillcolor} />
            <path d="M29.836 28.3442C29.8657 28.3279 29.9038 28.3023 29.9324 28.2874C31.9654 27.219 33.6168 27.0573 34.9642 27.0398C35.974 27.0523 37.4918 27.1823 39.4391 28.0299C39.1036 26.8198 37.0671 25.8523 34.6689 25.8523C32.0651 25.852 29.8678 26.9904 29.8359 28.3442L29.836 28.3442Z" fill={fillcolor} />
            <path d="M37.9042 25.3608C40.539 24.2908 43.1214 22.6801 44.197 21.4343C44.6437 20.9168 44.8053 20.4692 44.6761 20.1052C44.3952 19.3088 42.8854 18.7859 42.033 18.5749C41.3857 20.7799 39.5099 23.3257 37.8477 25.3423C37.8658 25.3495 37.8866 25.3536 37.9042 25.3608L37.9042 25.3608Z" fill={fillcolor} />
            <path d="M34.79 35.0916C30.7437 35.0916 27.4512 38.3838 27.4512 42.4305C27.4512 46.4768 30.7434 49.7693 34.79 49.7693C38.8364 49.7693 42.1283 46.4771 42.1283 42.4305C42.128 38.3841 38.8364 35.0916 34.79 35.0916ZM35.4573 45.8465V46.7009H34.1918V45.8501C32.9719 45.6641 32.2693 44.9209 32.1874 43.9004H33.6856C33.807 44.4456 34.2275 44.7891 34.8587 44.7891C35.64 44.7891 35.8896 44.4147 35.8896 43.9917C35.8896 43.4733 35.6149 43.2197 34.6352 42.9402C32.851 42.4266 32.3299 41.7679 32.3299 40.7748C32.3299 39.7052 33.1155 39.0445 34.117 38.8546V38.1606H35.3732V38.8394C36.6854 39.0382 37.1536 39.8483 37.2319 40.6712H35.7325C35.6566 40.3775 35.5197 39.9028 34.7117 39.9028C34.0331 39.9028 33.8402 40.3028 33.8402 40.6287C33.8402 41.0912 34.0902 41.2977 35.2206 41.6162C36.7988 42.0563 37.3923 42.6929 37.3923 43.8188C37.392 45.0105 36.6143 45.6726 35.4572 45.8464L35.4573 45.8465Z" fill={fillcolor} />
            <path d="M50.2391 45.2179C49.8002 38.4371 42.9595 30.9119 39.5292 29.2388C38.5215 28.7474 37.6325 28.4686 36.8712 28.3099C36.9995 28.4968 37.1227 28.7123 37.2364 28.9638C38.3205 31.3628 39.9355 31.9605 40.0039 31.9845C40.2455 32.0706 40.3744 32.3363 40.2899 32.5783C40.2059 32.8206 39.9435 32.9488 39.6991 32.8667C39.6158 32.8387 37.6459 32.1381 36.3858 29.3477C36.0495 28.6024 35.6367 28.3045 35.3236 28.1819V31.9881C35.3236 32.2824 35.0847 32.5214 34.7903 32.5214C34.496 32.5214 34.257 32.2824 34.257 31.9881V28.2813C33.8778 28.4727 33.373 28.8691 32.8675 29.7253C31.1288 32.6744 29.0755 33.6544 28.989 33.6949C28.9259 33.7241 28.8586 33.7387 28.7928 33.7387C28.6175 33.7387 28.4497 33.639 28.3702 33.4696C28.2607 33.2372 28.361 32.9592 28.5934 32.8491C28.6122 32.8408 30.4824 31.9322 32.0636 29.2512C32.2752 28.8923 32.4916 28.6137 32.7065 28.3673C32.033 28.5292 31.2672 28.7908 30.4291 29.231C27.2018 30.9285 19.2003 38.7241 19.0038 45.4821C18.9094 48.7276 20.5874 51.413 23.9924 53.463C25.6002 54.432 27.5927 54.5778 29.9122 53.8966C29.9393 53.8885 29.9655 53.893 29.9926 53.8891C30.0268 53.8844 30.0593 53.8778 30.0941 53.8799C30.1372 53.8823 30.1765 53.8942 30.217 53.907C30.2369 53.9132 30.2578 53.9115 30.2774 53.9204C33.3589 55.2769 37.4859 55.3614 40.6734 54.1379C40.6886 54.1323 40.7047 54.1347 40.7202 54.1302C40.7526 54.121 40.785 54.1171 40.819 54.1138C40.8538 54.1112 40.8868 54.1091 40.9207 54.1129C40.9365 54.1144 40.9514 54.1094 40.9669 54.1124C42.374 54.3891 44.3757 54.5049 45.9346 53.4749C49.0147 51.4437 50.4629 48.6663 50.2396 45.2182L50.2391 45.2179ZM34.7902 50.8366C30.1552 50.8366 26.385 47.0664 26.385 42.4314C26.385 37.7963 30.1552 34.0262 34.7902 34.0262C39.4247 34.0262 43.1947 37.7963 43.1947 42.4314C43.1947 47.0664 39.4245 50.8366 34.7902 50.8366Z" fill={fillcolor} />
        </svg>
    );
}

export default Icon;
