import React from "react";

function Icon() {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L17 7" stroke="url(#paint0_linear_32_1275)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 1L17 7L12 13" stroke="#FFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_32_1275" x1="1" y1="7" x2="17" y2="7" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFFFF" />
                    <stop offset="1" stopColor="#FFFFF" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default Icon;
