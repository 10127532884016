import React, { useEffect } from 'react';
import './style.css';
import DBWhite from '../../assets/images/DBWhite.svg';
import DBBlack from '../../assets/images/DBBlack.svg';
import BlueIcon from '../../assets/icons/blue-icon-holder';
import StarTop from '../../assets/images/Top.png';
import StarBottom from '../../assets/images/Bottom.png';
import Mockups from '../../assets/images/Mockups.png';
import DBMock from '../../assets/images/DBMock.png';
import DBMockup from '../../assets/images/DBMockup.png';

import { useLocation } from 'react-router-dom';
import { PartnerBar, TextCol } from '../../components';
import { Col, Row } from 'react-bootstrap';
export const Dabarhali = () => {
  const cards = [
    {
      title: 'Dabarhali connects Manufacturers',
      text: 'Dabarhali has a diverse supplier network; with lots of manufacturers across different industries and categories',
    },
    {
      title: 'Dabarhali connects MSMEs to Financers',
      text: 'Dabarhali allows MSMEs to grow by conneting them with financing entities via our platform',
    },
    {
      title: 'Dabarhali works with SMEs',
      text: 'Dabarhali is introducing the term “Procurement as a service” for SMEs. Simply, you can get all your business needs from your phone',
    },
    {
      title: 'Manufacturers can go Global with Dabarhali',
      text: 'Through our partner network in Africa; local manufacturers will be able to penetrate different markets while keeping the cost of expansion to a minimum',
    },
  ];

  const location = useLocation();

  useEffect(() => {
    const background = document.getElementById('background');
    background.style.backgroundColor = 'white';
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="super-container special">
      <div className="in-section blue">
        <div className="star top">
          <img src={StarTop} alt="star" />
        </div>
        <div className="header no-margin">
          <img src={DBWhite} alt="dabarhali" style={{ margin: '1rem' }}></img>
          <TextCol
            title={'Manage Your Business'}
            subtitle="Dabarhali is a new B2B Marketplace"
            text="Dabarhali is a new B2B Marketplace. As a business; you will be able to easily
                    manage your purchasing needs as well as access to different financing options to help you fulfill your order"
            theme="dark"
            size="small"
          />
        </div>
        <img src={Mockups} className="mockups" alt="mockups" />
        <div className="star bottom">
          <img src={StarBottom} alt="star" />
        </div>
      </div>
      <div className="white-padding" />
      <div className="in-section no-margin remove-padding no-pad">
        <PartnerBar blue="blue" />
        <div className="header">
          <img src={DBBlack} alt="dabarhali"></img>
          <TextCol
            title={'You can order your products in minutes'}
            text="Our E-commerce experience is something almost everyone is used to. We chose this experience to make it easier for our clients to find all their business needs in a matter of minutes"
            size="small"
          />
        </div>
      </div>
      <div className="in-section grey margin">
        <Row>
          <Col>
            <TextCol
              title={`Browse different Suppliers \n Choose the best Prices`}
              center="center"
            />
          </Col>
          <Col className="description">
            <TextCol
              center="center"
              text="Our diverse “Supplier Network” makes it convenient for businesses to consolidate their purchasing to ONE place, while ensuring the BEST price possible for each item"
              size="font-small"
            />
          </Col>
        </Row>
        <Row>
          <Col className="white-card" md={5} xs={11}>
            <TextCol
              text={cards[0].text}
              title={cards[0].title}
              Icon={() => <BlueIcon type={0} />}
              center="center"
              size="split-margin"
            />
          </Col>
          <Col className="white-card" md={5} xs={11}>
            <TextCol
              text={cards[1].text}
              title={cards[1].title}
              Icon={() => <BlueIcon type={1} />}
              center="center"
              size="split-margin"
            />
          </Col>
        </Row>
        <Row>
          <Col className="white-card" md={5} xs={11}>
            <TextCol
              text={cards[2].text}
              title={cards[2].title}
              Icon={() => <BlueIcon type={2} />}
              center="center"
              size="split-margin"
            />
          </Col>
          <Col className="white-card" md={5} xs={11}>
            <TextCol
              text={cards[3].text}
              title={cards[3].title}
              Icon={() => <BlueIcon type={3} />}
              center="center"
              size="split-margin"
            />
          </Col>
        </Row>
      </div>
      <div className="in-section margin small">
        <Row>
          <Col>
            <TextCol
              title={'You can manage your order with ease'}
              subtitle="Our Ordering Experience"
              text="Through our order management system, you can track your order history including a detailed breakdown of the cart as well as any financing granted"
              center="center"
              theme="dark3"
            />
            <Row className="grey-card">
              <Col>
                <div className="small-title">
                  Choose the Best Payment Option
                </div>
                <div className="description">
                  You can pay in Cashier Checks, Bank Transfer or any form of
                  digital payment or you can apply for Financing through Us
                </div>
              </Col>
            </Row>
            <Row className="grey-card">
              <Col>
                <div className="small-title">
                  Choose the Right Financial Provider
                </div>
                <div className="description">
                  Our diverse ecosystem offers a variety of financial products
                  that will serve all our clients’ needs in all categories
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="blue-card">
            <TextCol
              title={'Sale payments system'}
              text="Becoming a merchant has never been easier. Just create a cart with your required inventory, choose to pay later and wait for your items to be delivered to your business' doorstep"
              theme="dark"
              center="center"
            />
            <img src={DBMockup} alt="dabarhali" className="adjust"></img>
          </Col>
        </Row>
      </div>
      <div className="in-section dark-blue">
        <div className="header">
          <div className="subtitle dark">Join our Supplier Network</div>
          <div className="title white">
            Manage your Orders with our Supplier Dashboard
          </div>
          <Row className="center light">
            <div
              className="sign-up-button visible blue"
              onClick={() =>
                window.open(
                  'https://sell.dabarhali.com/become-a-seller',
                  '_blank'
                )
              }
            >
              <p>Join Now</p>
            </div>
          </Row>
        </div>
        <img src={DBMock} className="mockups" alt="mockups" />
      </div>
      <div className="white-padding small" />
    </div>
  );
};
