import React from "react";

function Icon() {
    return (
        <svg width="65" height="65" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="70" height="70" rx="10" fill="#F4F7FD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.519 36.4019C39.519 38.9183 37.4791 40.9579 34.9626 40.9579C32.4462 40.9579 30.4062 38.9183 30.4062 36.4019C30.4062 33.8854 32.4462 31.8455 34.9626 31.8455C37.4791 31.8455 39.519 33.8854 39.519 36.4019Z" fill="url(#paint0_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.3227 40.0044C42.7909 41.6699 45.5202 51.3665 44.8996 55.0001H25.0261C24.4048 51.3662 27.1341 41.67 30.603 40.0044C31.6403 41.2585 33.2082 42.0573 34.9628 42.0573C36.7167 42.0573 38.2845 41.2585 39.3225 40.0044H39.3227Z" fill="url(#paint1_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M48.1897 32.3181C48.1897 34.4977 46.4229 36.2645 44.2433 36.2645C42.0637 36.2645 40.2969 34.4977 40.2969 32.3181C40.2969 30.1385 42.0637 28.3713 44.2433 28.3713C46.4229 28.3713 48.1897 30.1385 48.1897 32.3181Z" fill="url(#paint2_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M48.0211 35.4383C51.0255 36.8815 53.3892 45.28 52.8511 48.4272H45.3018C44.2682 44.4643 42.306 40.3457 39.9434 39.0872C40.3756 38.2884 40.6204 37.3735 40.6204 36.4021C40.6204 36.1085 40.598 35.8205 40.5547 35.5387C41.4532 36.5668 42.7733 37.2164 44.2454 37.2164C45.7644 37.2164 47.1224 36.5242 48.0215 35.438L48.0211 35.4383Z" fill="url(#paint3_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.6291 32.3181C29.6291 34.4977 27.8623 36.2645 25.6827 36.2645C23.5031 36.2645 21.7363 34.4977 21.7363 32.3181C21.7363 30.1385 23.5031 28.3713 25.6827 28.3713C27.8623 28.3713 29.6291 30.1385 29.6291 32.3181Z" fill="url(#paint4_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.9072 35.4382C18.9029 36.8814 16.5388 45.2799 17.0767 48.4271H24.6266C25.6603 44.4642 27.6232 40.3456 29.9851 39.0871C29.5535 38.2883 29.3081 37.3734 29.3081 36.402C29.3081 36.1084 29.3308 35.8197 29.3737 35.5382C28.4753 36.5667 27.1549 37.2166 25.6831 37.2166C24.1634 37.2166 22.8061 36.5244 21.907 35.4382H21.9072Z" fill="url(#paint5_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.533 27.0814C38.533 29.0521 36.9354 30.6497 34.9647 30.6497C32.994 30.6497 31.3965 29.0521 31.3965 27.0814C31.3965 25.1107 32.994 23.5132 34.9647 23.5132C36.9354 23.5132 38.533 25.1107 38.533 27.0814Z" fill="url(#paint6_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M46.9724 23.6454C46.9724 25.6161 45.3749 27.2137 43.4042 27.2137C41.4335 27.2137 39.8359 25.6161 39.8359 23.6454C39.8359 21.6747 41.4335 20.0771 43.4042 20.0771C45.3749 20.0771 46.9724 21.6747 46.9724 23.6454Z" fill="url(#paint7_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.0919 23.6454C30.0919 25.6161 28.4944 27.2137 26.5237 27.2137C24.553 27.2137 22.9551 25.6161 22.9551 23.6454C22.9551 21.6747 24.553 20.0771 26.5237 20.0771C28.4944 20.0771 30.0919 21.6747 30.0919 23.6454Z" fill="url(#paint8_linear_32_837)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.1574 18.1941C38.1574 19.9579 36.7274 21.3878 34.9636 21.3878C33.1994 21.3878 31.7695 19.9579 31.7695 18.1941C31.7695 16.4299 33.1995 15 34.9636 15C36.7275 15 38.1574 16.43 38.1574 18.1941Z" fill="url(#paint9_linear_32_837)" />
            <defs>
                <linearGradient id="paint0_linear_32_837" x1="34.9626" y1="31.8455" x2="34.9626" y2="40.9579" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint1_linear_32_837" x1="34.9628" y1="40.0044" x2="34.9628" y2="55.0001" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint2_linear_32_837" x1="44.2433" y1="28.3713" x2="44.2433" y2="36.2645" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint3_linear_32_837" x1="46.4356" y1="35.438" x2="46.4356" y2="48.4272" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint4_linear_32_837" x1="25.6827" y1="28.3713" x2="25.6827" y2="36.2645" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint5_linear_32_837" x1="23.4925" y1="35.4382" x2="23.4925" y2="48.4271" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint6_linear_32_837" x1="34.9647" y1="23.5132" x2="34.9647" y2="30.6497" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint7_linear_32_837" x1="43.4042" y1="20.0771" x2="43.4042" y2="27.2137" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint8_linear_32_837" x1="26.5235" y1="20.0771" x2="26.5235" y2="27.2137" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
                <linearGradient id="paint9_linear_32_837" x1="34.9634" y1="15" x2="34.9634" y2="21.3878" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2750F4" />
                    <stop offset="1" stopColor="#0C2AA3" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default Icon;