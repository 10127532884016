import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Ellipse from "../../assets/icons/ellipse";
import Logic from "../../assets/icons/financeImage";
import Asset1 from "../../assets/images/asset1.png";
import Asset2 from "../../assets/images/asset2.png";
import "./style.css";
import { useLocation } from "react-router-dom";

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;
  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = (ele) => {
  let element = document.getElementById(ele.id);
  let headerOffset = "70";
  let elementPosition = element.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const Architecture = () => {
  const [visibleSection, setVisibleSection] = useState("Our Logic");
  const { hash } = useLocation();

  const headerRef = useRef(null);
  const singRef = useRef(null);
  const logicRef = useRef(null);
  const archiRef = useRef(null);
  const sectionRefs = [
    { section: "Our Logic", ref: logicRef },
    { section: "Architecture", ref: archiRef },
    { section: "Singularity", ref: singRef },
  ];
  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight + 70;
      if (scrollPosition <= 2600 && scrollPosition >= 600) {
        const elem = document.getElementById("progress");
        const width = (scrollPosition - 600) / (2600 - 600);
        elem.style.width = width * 100 + "%";
      }
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return -1;
      });
      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
        // if (hash === '#architecture' || hash === '')
        //     scrollTo(selected.ref.current)
      } else if (!selected && visibleSection) {
        if (visibleSection !== "Singularity") setVisibleSection("Our Logic");
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection, sectionRefs, hash]);

  return (
    <div className="mobile-margin" id="architecture">
      <div className={`menu-container content`}>
        <div className="sticky" ref={headerRef}>
          <div className="bar">
            <div className={"bar-indicator plain"}></div>
            <div className={"bar-indicator active"} id="progress"></div>
          </div>
          <Col className="side-menu" md={3}>
            <ul className="list-container">
              {sectionRefs.map((item, i) => (
                <li
                  key={i}
                  className={`tab-menu-lists ${
                    visibleSection === item.section ? "active" : ""
                  }`}
                >
                  <div
                    className={
                      visibleSection === item.section
                        ? "active side-menu-text"
                        : "side-menu-text"
                    }
                    onClick={() => {
                      scrollTo(item.ref.current);
                    }}
                  >
                    <div className="num">0{i + 1}</div>
                    {item.section}
                  </div>
                </li>
              ))}
            </ul>
            <Ellipse deg={"-40deg"} left={"-7rem"} />
          </Col>
        </div>
        <Row className="neg-margin">
          <Col md={4} className="invisible">
            <ul className="list-container">
              <li className="tab-menu-lists">
                <div>
                  <div className="num">00</div>
                </div>
              </li>
            </ul>
          </Col>
          <Col className="content-column" md={8}>
            <div className="content-block" id="Our Logic" ref={logicRef}>
              <div className="content-image">
                <img src={Asset2} alt="singularity" />
              </div>
              <div className="content-section">
                <div className="line-tick" />
                <div className="content-text">
                  <div className="content-title side-menu-text">Our Logic</div>
                  <div className="content-description description">
                    The opportunity to help uplift millions of people’s lives
                    and allow them to realise their potential is the reason why
                    Financial Inclusion is important. The ability to use
                    technology to achieve this is where true innovation thrives;
                    there is “No Universal Roadmap” to show how countries can
                    achieve Financial Inclusion
                  </div>
                </div>
              </div>
            </div>
            <div className="content-block" id="Architecture" ref={archiRef}>
              <div className="content-image">
                <img src={Asset1} alt="singularity" />
              </div>
              <div className="content-section">
                <div className="line-tick" />
                <div className="content-text ">
                  <div className="content-title side-menu-text">
                    Our Architecture
                  </div>
                  <div className="content-description description">
                    We created a tool for people to; manage their daily/monthly
                    transactions, payments and to run and grow their businesses.
                    A full financial services ecosystem that revolves around the
                    users’ needs and wants; utilizing the newest tools available
                    including Blockchain, AI, etc
                  </div>
                </div>
              </div>
            </div>

            <div className="content-block" id="Singularity" ref={singRef}>
              <div className="content-image">
                <Logic />
              </div>
              <div className="content-section">
                <div className="line-tick" />
                <div className="content-text">
                  <div className="content-title side-menu-text">
                    Singularity
                  </div>
                  <div className="content-description description">
                    Singularity Finance is a new Fintech, that is tackling
                    Financial Inclusion and Sustainable Developement with new
                    and innovative solutions.
                    {/* We all have personal expenses, bills & payments of different kinds; whether it’s to manage our household or our businesses. In this post-covid world, we need to be empowered by tools that make our lives easier and our financials accessible */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
